import React, { useState, forwardRef, useImperativeHandle } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { validateEmailField } from "../utils";

interface Props {
  onSubmitProtocolLogsForm: (queryParams: { email: string }) => void;
}

const ProtocolLogs = forwardRef((props: Props, ref) => {
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const intl = useIntl();

  useImperativeHandle(ref, () => ({
    handleSubmit: () => {
      // Clear previous error immediately
      setEmailError("");

      // Perform validation
      const error = validateEmailField(email);
      if (error) {
        setEmailError(error);
        return;
      }

      const queryParams = {
        email: email.trim(),
      };

      props.onSubmitProtocolLogsForm(queryParams);
    },
  }));

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  return (
    <div className="logs-filter-options-container">
      <div>
        <label className="logs-label input-label">
          <FormattedMessage id="enter_email_address" />
        </label>
        <input
          name="email"
          type="text"
          className={`logs-input input ${emailError ? "input-error" : ""}`}
          placeholder={intl.formatMessage({ id: "enter_email_address" })}
          autoFocus
          onChange={handleEmailChange}
          value={email}
        />
        <div className="error dashboard-error">{emailError || ""}</div>
      </div>
    </div>
  );
});

export default ProtocolLogs;
