import { getDateForLast24Hours, STATUS, DIRECTION } from "../components/utils";
import { ProtocolLog } from "./types/logsType";

export interface LogDetailsState {
  fetchingLogs: boolean,
  fetchLogsError: string,
  fetchLogsPayload: LogDetail[],
  totalRecords: number,
  filterCountMap: object,
  senderMailSubmitted: string,
  receiverMailSubmitted: string,
  ipSubmitted: string,
  isLogFetchCancelled: boolean,
  timestamp: number,
  status: string,
  direction: string,
  recordsPerPage: number,
  page: number,
  defaultNoOfRecords: number,
  ipLogsData?: IpLogDetail
  protocolLogsData?: ProtocolLog[]
}

export interface LogDetail {
  dateTime: string,
  from: string,
  to: string,
  direction: string
  status: string,
  info: string,
  clientIP: string,
  userAgent: string,
  isForwarded: boolean,
  returnPath: string,
  originalTo: string,
}


// Define the Reject type
export interface Reject {
  env: string;
  ip: string;
  reason: string;
  info: string;
  dateTime: string;
  direction: string;
}

// Define the Block type
export interface Block {
  env: string;
  ip: string;
  created: string;
  expiry?: string;
  reason: string;
  action: string;
  direction: string;
}

export interface IpLogDetail {
  status: string;
  rejects: Reject[];
  blocks: Block[];
}
const defaultStatus = [STATUS.SUCCESS, STATUS.FAILURE, STATUS.DEFERRED].join()
const defaultDirection = [DIRECTION.INCOMING, DIRECTION.OUTGOING].join()

const defaultState: LogDetailsState = {
  fetchingLogs: false,
  fetchLogsError: "",
  fetchLogsPayload: [],
  totalRecords: 0,
  filterCountMap: {},
  senderMailSubmitted: "",
  receiverMailSubmitted: "",
  ipSubmitted: "",
  isLogFetchCancelled: false,
  timestamp: getDateForLast24Hours(),
  status: defaultStatus,
  direction: defaultDirection,
  recordsPerPage: 1000,
  page: 1,
  defaultNoOfRecords: (localStorage.getItem("defaultNoOfRecords")) ? Number(localStorage.getItem("defaultNoOfRecords")) : 5
};

const LogsReducer = (state: LogDetailsState = defaultState, action: any) => {
  switch (action.type) {
    case "FETCH_LOGS_REQUEST": {
      return {
        ...state,
        fetchingLogs: true,
        isLogFetchCancelled: false,
        fetchLogsPayload: action.payload.page === 1 ? [] : state.fetchLogsPayload,
        totalRecords: action.payload.page === 1 ? 0 : state.totalRecords,
        filterCountMap: action.payload.page === 1 ? {} : state.filterCountMap,
        senderMailSubmitted: action.payload.senderMailSubmitted,
        receiverMailSubmitted: action.payload.receiverMailSubmitted,
        timestamp: action.payload.timestamp,
        status: action.payload.status,
        direction: action.payload.direction,
        recordsPerPage: action.payload.recordsPerPage,
        page: action.payload.page
      };
    }

    case "FETCH_IP_LOGS_REQUEST": {
      return {
        ...state,
        fetchingLogs: true,
        isLogFetchCancelled: false,
        fetchLogsPayload: action.payload.page === 1 ? [] : state.fetchLogsPayload,
        totalRecords: action.payload.page === 1 ? 0 : state.totalRecords,
        filterCountMap: action.payload.page === 1 ? {} : state.filterCountMap,
        ipSubmitted: action.payload.ipSubmitted,
        recordsPerPage: action.payload.recordsPerPage,
        page: action.payload.page
      }
    }

    case "FETCH_LOGS_SUCCESS": {
      if (state.isLogFetchCancelled) {
        return {
          ...state
        }
      }
      else {
        return {
          ...state,
          fetchingLogs: false,
          isLogFetchCancelled: false,
          fetchLogsError: action.payload.data.logs?.length === 0 ? "logs-not-found" : "",
          fetchLogsPayload: action.payload.data.logs ? action.payload.data.logs : state.fetchLogsPayload,
          ipLogsData: action.payload.data.ipLogsData,
          totalRecords: action.payload.data.total,
          filterCountMap: action.payload.data.statusCount,
          protocolLogsData: action.payload.data.protocolLogsData
        };
      }
    }

    case "FETCH_LOGS_ERROR": {
      if (state.isLogFetchCancelled) {
        return {
          ...state
        }
      }
      else {
        return {
          ...state,
          fetchingLogs: false,
          isLogFetchCancelled: false,
          fetchLogsError: action.payload.error,
          fetchLogsPayload: [],
          totalRecords: 0,
          filterCountMap: {},
          protocolLogsData: undefined // Remove protocolLogsData on error i.e. when logs are not fetched
        };
      }
    }

    case "CANCEL_LOG_REQUEST": {
      return {
        ...defaultState,
        isLogFetchCancelled: true,
        defaultNoOfRecords: (localStorage.getItem("defaultNoOfRecords")) ? Number(localStorage.getItem("defaultNoOfRecords")) : 5
      }
    }

    case "CLEAR_LOG_STORE": {
      return {
        ...defaultState,
        defaultNoOfRecords: 5
      }
    }

    case "UPDATE_DEFAULT_NUMBER_OF_RECORDS": {
      return {
        ...state,
        defaultNoOfRecords: action.payload.defaultNoOfRecords
      }
    }

    default:
      return state;
  }
}

export default LogsReducer