import React from 'react';
import { connect } from "react-redux";
import { RootState } from '../reducers';
import { bindActionCreators } from 'redux';
import { ReactComponent as CloseGrey } from "../assets/close-grey.svg"
import { ReactComponent as RefundSuccess } from "../assets/billing-upgrade-to-pro.svg"
import { AllTransactionsInfo, RefundnInfo } from "../model/refundInfo"
import { requestRefundAction } from "../actions/orderActions"
import { getCurrencySymbol } from './utils';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import moment from 'moment';
import { showPicker } from '../utils/helper';

type Plan = {
    id: number,
    name: string,
    planType: string,
    isTrial: boolean,
    isPaid: boolean,
}

type Props = {
    token: string
    domainId: number
    domainName: string,
    plan: Plan,
    orderId: string,
    allTransactionsInfo: AllTransactionsInfo,
    refundInfo: RefundnInfo,
    currentRegion: string,
    availablePlans: Plan[],
    noOfAccounts: number,
    totalAccountsInUse: number,
    closeModal: (a: Number | null, b: Number | null) => void,
    requestRefundAction: (payload: RefundRequestPayload, token: string, region: string) => void
} & WrappedComponentProps
interface OrderAction {
    id: string,
    title: string | React.ReactElement,
    enabled: boolean
}

interface RefundRequestPayload {
    domainId: number,
    action: string,
    reason: string,
    domainName: string,
    orderId: string,
    currency: string,
    refundAmount?: number,
    newPlanType?: string,
    numberOfAccounts?: number,
    refundAfter?: string
}

const today = moment().format('YYYY-MM-DD');
const orderActionMap = {
    DELETE_ORDER: "delete_order",
    MOVE_TO_FREE: "move_to_free",
    ADVANCE_REFUND: "advance_refund",
}
const REFUND_AFTER_DAYS = 30;

class ProcessRefund extends React.Component<Props> {
    orderActions: OrderAction[] = []
    state = {
        reason: "",
        selectedAction: "",
        refundAfter: moment().subtract(REFUND_AFTER_DAYS, "days").format("YYYY-MM-DD"),
        selectedPlanType: "",
        numberOfAccounts: null,
        refundAmount: "",
        formErrors: {
            refundAfter: "",
            selectedPlanType: "",
            numberOfAccounts: "",
            refundAmount: "",
        }
    }

    constructor(props: Props) {
        super(props)
        this.handleReasonInputChange = this.handleReasonInputChange.bind(this)
        this.handleRadioButtonClick = this.handleRadioButtonClick.bind(this)
        this.handleCloseClick = this.handleCloseClick.bind(this)
        this.orderActions.push(
            {
                id: orderActionMap.DELETE_ORDER,
                title: <FormattedMessage id={orderActionMap.DELETE_ORDER} />,
                enabled: true
            },
            {
                id: orderActionMap.MOVE_TO_FREE,
                title: <FormattedMessage id={orderActionMap.MOVE_TO_FREE} />,
                enabled: true
            },
            {
                id: orderActionMap.ADVANCE_REFUND,
                title: <FormattedMessage id={orderActionMap.ADVANCE_REFUND} />,
                enabled: true
            }
        )
        this.validateAdvRefundForm = this.validateAdvRefundForm.bind(this);
        this.updatePlanType = this.updatePlanType.bind(this);
        this.getRefundLayout = this.getRefundLayout.bind(this);
    }

    componentDidMount(): void {
        if (this.props.allTransactionsInfo?.totalRefundableAmount <= 0) {
            this.setState({
                selectedAction: orderActionMap.ADVANCE_REFUND
            })
        }
    }

    handleReasonInputChange = (item: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({
            reason: item.target.value
        })
    }

    handleCloseClick() {
        this.props.closeModal(this.props.domainId, null)
    }

    handleRadioButtonClick = (item: any) => {
        const selectedAction = item.target.id
        this.setState(prevState => {
            return {
                ...prevState,
                selectedAction: selectedAction
            }
        })
    }

    updatePlanType = (e: React.ChangeEvent<HTMLSelectElement>) => {
        this.setState({ selectedPlanType: e.target.value });
    }

    setRefundAfter = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            refundAfter: e.target.value
        });
    }

    setNumberOfAccounts = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            numberOfAccounts: e.target.value
        });
    }

    setRefundAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            refundAmount: e.target.value
        });
    }

    validateAdvRefundForm = () => {
        const {
            refundAfter,
            numberOfAccounts,
            refundAmount
        } = this.state;
        const intRefundAmount = Number(refundAmount);
        const { totalRefundableAmount } = this.props.allTransactionsInfo;
        const isRefundAmountNonPositive = intRefundAmount <= 0;

        let formErrors = {};

        // refund after date validation
        if (!refundAfter) {
            formErrors = { ...formErrors, refundAfter: this.props.intl.formatMessage({ id: 'refund_after_error' }) };
        }


        // refund amount validation
        if (!refundAmount) {
            formErrors = { ...formErrors, refundAmount: this.props.intl.formatMessage({ id: 'refund_amount_empty_error' }) };
        } else if (isRefundAmountNonPositive) {
            formErrors = { ...formErrors, refundAmount: this.props.intl.formatMessage({ id: 'refund_amount_valid_error' }, {
                amount: totalRefundableAmount
            }) };
        }

        // number of acc validation
        if (numberOfAccounts) {
            const intNumberOfAccounts = Number(numberOfAccounts);
            const maxAccountsToRefund = this.props.noOfAccounts - this.props.totalAccountsInUse;

            if (intNumberOfAccounts > maxAccountsToRefund || intNumberOfAccounts < 0) {
                formErrors = { ...formErrors, numberOfAccounts: this.props.intl.formatMessage({ id: 'number_of_acc_valid_error' }, {
                    number: maxAccountsToRefund
                }) };
            }
        }

        this.setState({ formErrors });
        return !Object.keys(formErrors).length;
    }

    handleSubmit = () => {
        const {
            selectedAction,
            reason,
            selectedPlanType,
            numberOfAccounts,
            refundAfter,
            refundAmount,
        } = this.state;

        const isAdvanceRefund = selectedAction === orderActionMap.ADVANCE_REFUND;

        if ((isAdvanceRefund && !this.validateAdvRefundForm()) || this.props.refundInfo.inProgress) return false;

        const payload: RefundRequestPayload = {
            domainId: this.props.domainId,
            action: selectedAction,
            reason,
            domainName: this.props.domainName,
            orderId: this.props.orderId,
            currency: this.props.allTransactionsInfo.currency,
            ...(
                isAdvanceRefund ? {
                    refundAmount: Number(refundAmount),
                    numOfAccounts: numberOfAccounts ? Number(numberOfAccounts) : null,
                    refundAfter,
                } : {}
            )
        }

        if (selectedPlanType) {
            payload.newPlanType = selectedPlanType;
        }

        this.props.requestRefundAction(payload, this.props.token, this.props.currentRegion)
    }

    render() {
        const showRefundSuccess = this.props.refundInfo.success && this.props.refundInfo.transactions?.length;
        const totalRefunded = showRefundSuccess ? this.props.refundInfo.transactions.reduce((acc, t) => {
            if (t.status === "success" && t.amount) {
                acc += Number(t.amount);
            }
            return acc;
        }, 0) : 0;
        const currency = totalRefunded && getCurrencySymbol(this.props.refundInfo.transactions[0]?.currency?.toUpperCase());

        return (
            <div>
                <CloseGrey className="alert-modal-close-button" onClick={this.handleCloseClick} />
                {showRefundSuccess ? this.getSuccessLayout(`${currency}${totalRefunded}`) : this.getRefundLayout()}
            </div>
        )
    }

    getRefundLayout = () => {
        const allPlansExcludingCurrent = this.props.availablePlans.filter(p => p.planType !== this.props.plan.planType);
        const { formErrors, selectedAction, selectedPlanType, numberOfAccounts, refundAmount } = this.state;
        const refundButtonText = this.props.refundInfo.inProgress ?
            <div className="modal-button-div">
                <div className="modal-spinner" />
                <FormattedMessage id='processing_refund' />
            </div> : <FormattedMessage id='process_refund' />
        const hasRefundAmount = this.props.allTransactionsInfo.totalRefundableAmount > 0;
        const refundableAmount = this.props.allTransactionsInfo?.currency
            && `${getCurrencySymbol(this.props.allTransactionsInfo.currency.toUpperCase())} ${this.props.allTransactionsInfo.totalRefundableAmount}`;
        const maxAccountsToRefund = this.props.noOfAccounts - this.props.totalAccountsInUse;

        return <div className="refund-details-container">
            <div className="process-refund-heading"><FormattedMessage id='process_refund' /></div>
            <div className="process-refund-subheading">{this.props.domainName}</div>
            {
                hasRefundAmount ? (
                    <>
                        <div className="refund-details-box">
                            <text className="refund-detail-header"><FormattedMessage id='full_refund' /></text>
                            <text className="refund-detail-header">{refundableAmount}</text>
                        </div>
                        <div className="refund-reason-heading"><FormattedMessage id='select_action_for_order' /></div>
                            <table className="refund-action-radio-table">
                                <tr>
                                    {
                                        this.orderActions
                                            .map((action) => {
                                                return (
                                                    <td key={action.id}>
                                                        <div className="refund-radio-div">
                                                            <input className="refund-radio-input" type="radio"
                                                                id={action.id}
                                                                disabled={!action.enabled}
                                                                onClick={this.handleRadioButtonClick}
                                                                checked={action.id === selectedAction} />
                                                            <label htmlFor={action.id} className={action.enabled ? "refund-radio-text" : "refund-radio-text-disabled"}>{action.title}</label>
                                                        </div>
                                                    </td>
                                                )
                                            })
                                    }
                                </tr>
                            </table>
                    </>
                ) : null
            }
            {
                selectedAction === orderActionMap.ADVANCE_REFUND ?
                <>
                    <fieldset className="t-form-field mt2">
                        <FormattedMessage tagName="label" id="select-plan-type" />
                        <select
                            className="block"
                            value={selectedPlanType}
                            onChange={this.updatePlanType}
                        >
                            <option value="">Select a plan</option>
                            {
                                allPlansExcludingCurrent.map((plan) => (
                                    <option
                                        key={plan.planType}
                                        value={plan.planType}
                                    >
                                        {plan.name} (ID: {plan.id} Type: {plan.planType})
                                    </option>
                                ))
                            }
                        </select>
                        {formErrors?.selectedPlanType && <span>{formErrors?.selectedPlanType}</span>}
                    </fieldset>
                    <fieldset className="t-form-field mt2">
                        <FormattedMessage tagName="label" id="refund-after" />
                        <input
                            type="date"
                            max={today.toString()}
                            onFocus={showPicker}
                            onChange={this.setRefundAfter}
                            value={this.state.refundAfter}
                        />
                        {formErrors?.refundAfter && <span>{formErrors?.refundAfter}</span>}
                    </fieldset>
                    {
                        maxAccountsToRefund > 0 ?
                            <fieldset className="t-form-field mt2">
                                <FormattedMessage tagName="label" id="refund-number-of-accounts" />
                                <input
                                    type="number"
                                    onChange={this.setNumberOfAccounts}
                                    value={numberOfAccounts || ""}
                                    max={maxAccountsToRefund}
                                    placeholder={this.props.intl.formatMessage({ id: 'enter_max_account' }, { maxAccountsToRefund })}
                                />
                                {formErrors?.numberOfAccounts && <span>{formErrors?.numberOfAccounts}</span>}
                            </fieldset>
                            : null
                    }
                    <fieldset className="t-form-field mt2">
                        <FormattedMessage tagName="label" id="refund-amount" />
                        <input
                            type="number"
                            onChange={this.setRefundAmount}
                            value={refundAmount}
                            max={this.props.allTransactionsInfo.totalRefundableAmount}
                            placeholder={hasRefundAmount
                                ? this.props.intl.formatMessage({ id: 'enter_max_refund_amount' }, { refundableAmount })
                                : this.props.intl.formatMessage({ id: 'enter_refund_amount' })}
                        />
                        {formErrors?.refundAmount && <span>{formErrors?.refundAmount}</span>}
                    </fieldset>
                </> : null
            }
            <fieldset className="t-form-field mt2">
                <FormattedMessage tagName="label" id='specify_a_reason' />
                <textarea
                    className="refund-reason"
                    placeholder={this.props.intl.formatMessage({ id: 'enter_reason_for_refund' })}
                    onChange={this.handleReasonInputChange}
                    value={this.state.reason} />
            </fieldset>
            <div className="flex">
                <button
                    className="button button-primary refund-button"
                    disabled={!this.state.selectedAction}
                    onClick={this.handleSubmit}>
                    {refundButtonText}
                </button>
                <button
                    className="button refund-button modal-cancel-button"
                    onClick={this.handleCloseClick}>
                    <FormattedMessage id='cancel' />
                </button>
            </div>
            {this.props.refundInfo.error ? <div className="refund-error">{this.props.refundInfo.error} </div> : ""}
        </div>
    }

    getSuccessLayout = (totalRefunded: string) => {
        return <div className="refund-success-container">
            <RefundSuccess />
            <div className="refund-success-header"><FormattedMessage id='refund_successful' /></div>
            <div className="refund-success-subheader"><FormattedMessage id='refund_state_subheading' /></div>
            <div className="refund-success-subheader" dangerouslySetInnerHTML={{ __html: this.props.intl.formatMessage({ id: 'refund_state_details' }, { totalRefunded })}} />
        </div>
    }
}

const mapStateToProps = (state: RootState) => {
    const orders = state.orders as any
    return {
        token: state.auth.token,
        orderId: orders.currentOrderId,
        allTransactionsInfo: orders.allTransactionsInfo.data,
        refundInfo: orders.refundInfo,
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        ...bindActionCreators({ requestRefundAction }, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ProcessRefund))