export const ORDER_DETAIL_ACTION = {
    MOVE_TO_TRIAL_PLAN: 'moveToTrial',
    EXTEND_PLAN: 'extendPlan',
    CHANGE_PLAN: 'changePlan',
}

export const ACTION_DROPDOWN_KEY = {
    MAIL_LOGS: 'mailLogs',
    IP_LOGS: 'ipLogs',
    INVALID_EMAIL_CACHE: 'invalidEmailCache',
    PROTOCOL_LOGS: 'protocolLogs',
};



// Bulk migration
export const BULK_MIGRATION_ACTION = {
    CREATE_MAILBOXES_ONLY: 'CreateMailboxesOnly',
    CREATE_ORDERS_AND_MAILBOXES: 'CreateOrdersAndMailboxes',
};

export const VALIDATION_STATUS = {
    SUCCESS: 'success',
    FAILURE: 'failure',
};

  

export const BULK_MIGRATION_ACTION_LABELS = {
    [BULK_MIGRATION_ACTION.CREATE_MAILBOXES_ONLY]: "create-mailboxes-only",
    [BULK_MIGRATION_ACTION.CREATE_ORDERS_AND_MAILBOXES]: "create-orders-and-mailboxes",
};

export const HELP_ARTICLE_URLS = {
  TITAN_ORDER_ID:
    "https://partner.titan.email/hc/en-us/articles/36933279894041-How-to-get-Titan-order-Id-for-a-given-domain",
  MIGRATION_TOOLKIT:
    "https://partner.titan.email/hc/en-us/articles/36460486324121-Using-migration-toolkit-Creating-tasks-and-expected-file-formats",
  MIGRATION_TOOLKIT_FAQ:
    "https://partner.titan.email/hc/en-us/articles/35800655169049-Titan-Migration-Toolkit",
};


// table constants
export const COLUMN_TYPES = {
  ACTIONS: "actions",
  STATUS: "status",
};
