import React, { Component } from "react";
import { connect } from 'react-redux';
import DashboardForm from "./DashboardForm";
import GetLogsForm from "./getLogsForm";
import { bindActionCreators } from 'redux';
import { fetchOrderList } from "../actions/orderActions";
import { fetchLogs, fetchIpLogs, fetchProtocolLogs } from "../actions/logsActions";
import { fetchRedisCachedMailbox } from "../actions/redisCachedMailboxActions";
import { NavBarOptionValue } from "../model/navbarOptions";

class TopBar extends Component {
  state = {};
  constructor(props) {
    super(props);
    this.onSubmitDashboardForm = this.onSubmitDashboardForm.bind(this);
    this.onSubmitMailLogsForm = this.onSubmitMailLogsForm.bind(this);
    this.onSubmitIpLogsForm = this.onSubmitIpLogsForm.bind(this);
    this.onSubmitRedisCachedMailboxForm = this.onSubmitRedisCachedMailboxForm.bind(this);
    this.onSubmitProtocolLogsForm = this.onSubmitProtocolLogsForm.bind(this);
  }

  onSubmitDashboardForm(domainName) {
    const authToken = this.props.token;
    this.props.fetchOrderList(domainName, authToken);
  }

  onSubmitMailLogsForm(fetchLogsQueryParams) {
    const authToken = this.props.token;
    console.log("Submitting logs");
    this.props.fetchLogs(fetchLogsQueryParams, authToken);
  }

  onSubmitIpLogsForm(fetchLogsQueryParams) {
    const authToken = this.props.token;
    this.props.fetchIpLogs(fetchLogsQueryParams, authToken)
  }

  onSubmitRedisCachedMailboxForm(fetchLogsQueryParams) {
    const authToken = this.props.token;
    this.props.fetchRedisCachedMailbox(fetchLogsQueryParams, authToken);
  }

  onSubmitProtocolLogsForm(fetchLogsQueryParams) {
    const authToken = this.props.token;
    this.props.fetchProtocolLogs(fetchLogsQueryParams, authToken);
  }

  render() {
    let navbarOption = this.props.navbarOption;
    let sideBarContent;
    switch (navbarOption) {
      case NavBarOptionValue.ORDERS: sideBarContent =
        <DashboardForm
          onSubmitDashboardForm={this.onSubmitDashboardForm}
        />; break;

      case NavBarOptionValue.LOGS: sideBarContent = (
        <GetLogsForm
          onSubmitMailLogsForm={this.onSubmitMailLogsForm}
          onSubmitIpLogsForm={this.onSubmitIpLogsForm}
          onSubmitRedisCachedMailboxForm={this.onSubmitRedisCachedMailboxForm}
          onSubmitProtocolLogsForm={this.onSubmitProtocolLogsForm}
        />
      ); break;
      case NavBarOptionValue.ADMIN: break;
      case NavBarOptionValue.CREATE_TASK: break;
      case NavBarOptionValue.MONITOR_TASK: break;
      default: sideBarContent =
        <DashboardForm
          onSubmitDashboardForm={this.onSubmitDashboardForm}
        />; break;
    }
    return (
      <div className="top-bar">
        {sideBarContent}
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    token: store.auth.token,
    navbarOption: store.auth.navbarOption,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ fetchOrderList, fetchLogs, fetchIpLogs, fetchProtocolLogs, fetchRedisCachedMailbox }, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);
