import { dateTimeFormat } from "../../utils/helper";

/**
 * Transforms logs into CSV-friendly data format.
 * @param {ProtocolLog[]} logs - Array of protocol logs.
 * @returns {Object[]} Array of CSV-ready log objects.
 */
export const transformLogsToCSV = (logs) =>
  logs.map((log) => ({
    Timestamp: dateTimeFormat(log.log_timestamp),
    Protocol: log.protocol,
    "Remote IP": log.remote_ip,
    Status: log.status || "-",
    "Error Code": log.err_code || "-",
  }));
