import React, { Component, createRef } from 'react';
import { connect } from "react-redux"
import { DatePicker } from 'antd';
import moment from 'moment';

import { STATUS, DIRECTION, isIpAddressValid, validateEmailField } from "./utils"
import { bindActionCreators } from 'redux';
import { cancelLogsRequest } from "../actions/logsActions";
import { clearRedisCachedMailboxRequest } from "../actions/redisCachedMailboxActions";
import { FormattedMessage, injectIntl } from "react-intl";
import { ACTION_DROPDOWN_KEY } from '../utils/const';
import ProtocolLogs from './ProtocolLogs';

const { RangePicker } = DatePicker;

class GetLogsForm extends Component {
  FORM_TYPE_OPTIONS = {
    [ACTION_DROPDOWN_KEY.MAIL_LOGS]: <FormattedMessage id={ACTION_DROPDOWN_KEY.MAIL_LOGS} />,
    [ACTION_DROPDOWN_KEY.IP_LOGS]: <FormattedMessage id={ACTION_DROPDOWN_KEY.IP_LOGS} />,
    [ACTION_DROPDOWN_KEY.INVALID_EMAIL_CACHE]: <FormattedMessage id={ACTION_DROPDOWN_KEY.INVALID_EMAIL_CACHE} />,
    [ACTION_DROPDOWN_KEY.PROTOCOL_LOGS]: <FormattedMessage id={ACTION_DROPDOWN_KEY.PROTOCOL_LOGS} />,
  };
  TODAY = moment().endOf('day')
  DEFAULT_LOG_DURATION = 7;
  MAX_LOG_DURATION = 30;
  MAX_LOG_DURATION_DATE = this.TODAY.clone().subtract(this.MAX_LOG_DURATION, 'days').startOf('day');
  DEFAULT_LOG_DURATION_START_END = [moment().subtract(this.DEFAULT_LOG_DURATION, 'days'), moment()];

  state = {
    senderMail: "",
    senderMailSubmitted: "",
    receiverMail: "",
    receiverMailSubmitted: "",
    logsDuration: this.DEFAULT_LOG_DURATION_START_END,
    errorFromField: "",
    errorToField: "",
    errorIpAddress: "",
    ipAddress: "",
    redisSearchEmail: "",
    errorRedisSearchEmailField: "",
    isSuccessFilterSelected: true,
    isFailureFilterSelected: true,
    isDeferredFilterSelected: true,
    isBouncedFilterSelected: true,
    selectedDirection: DIRECTION.OUTGOING,
    formTypeSelected: ACTION_DROPDOWN_KEY.MAIL_LOGS,
    shouldShowFormTypeDropdown: false,
    recordsPerPage: 1000,
    page: 1
  }

  constructor(props) {
    super(props);
    this.handleSenderChange = this.handleSenderChange.bind(this)
    this.handleReceiverChange = this.handleReceiverChange.bind(this)
    this.toggleStatusFilter = this.toggleStatusFilter.bind(this)
    this.toggleDirectionFilter = this.toggleDirectionFilter.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.handleIpAddressChange = this.handleIpAddressChange.bind(this)
    this.getFilterCount = this.getFilterCount.bind(this)
    this.formRef = createRef();
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, false);
  }

  handleSenderChange = e => {
    const sender = e.target.value
    this.setState(prevState => ({
      ...prevState,
      senderMail: sender
    }))
  }

  handleReceiverChange = e => {
    const receiver = e.target.value
    this.setState(prevState => ({
      ...prevState,
      receiverMail: receiver
    }))
  }

  handleRangePickerSelection = (range) => {
    this.setState({ logsDuration: range });
  }

  handleIpAddressChange = e => {
    const value = e.target.value
    this.setState(prevState => ({
      ...prevState,
      ipAddress: value
    }))
  }

  handleRedisEmailChange = e => {
    const searchedEmail = e.target.value
    this.setState({
      redisSearchEmail: searchedEmail
    });
  }

  toggleStatusFilter = filter => {
    switch (filter) {
      case STATUS.SUCCESS:
        this.setState(prevState => ({
          ...prevState,
          isSuccessFilterSelected: !prevState.isSuccessFilterSelected
        }))
        break;
      case STATUS.FAILURE:
        this.setState(prevState => ({
          ...prevState,
          isFailureFilterSelected: !prevState.isFailureFilterSelected
        }))
        break;
      case STATUS.DEFERRED:
        this.setState(prevState => ({
          ...prevState,
          isDeferredFilterSelected: !prevState.isDeferredFilterSelected
        }))
        break;
      case STATUS.BOUNCED:
        this.setState(prevState => ({
          ...prevState,
          isBouncedFilterSelected: !prevState.isBouncedFilterSelected
        }))
        break;
      default:
        break;
    }
  }

  toggleDirectionFilter = direction => {
    this.setState((prevState) => ({
      ...prevState,
      selectedDirection: direction,
      errorFromField: "",
      errorToField: "",
    }));
  }

  handleClickOutside = (e) => {
    if (!this.node)
      return
    if (!this.node.contains(e.target)) {
      this.setState(prevState => ({
        ...prevState,
        shouldShowFormTypeDropdown: false
      })
      )
    }
  }

  handleMailLogSubmit = () => {
    const senderMail = this.state.senderMail;
    const receiverMail = this.state.receiverMail;
    const [pickerStartTime, pickerEndTime] = this.state.logsDuration;
    let errorFromField;
    let errorToField;
    
    if (this.state.selectedDirection === DIRECTION.OUTGOING) {
      errorFromField = validateEmailField(senderMail);
    } else if (this.state.selectedDirection === DIRECTION.INCOMING) {
      errorToField = validateEmailField(receiverMail);
    }
    this.setState({
      senderMailSubmitted: { senderMail },
      receiverMailSubmitted: { receiverMail },
      errorFromField: errorFromField,
      errorToField: errorToField
    })
    if (errorFromField || errorToField) {
      return
    }
    const statusFilterList = []
    if (this.state.isSuccessFilterSelected) {
      statusFilterList.push(STATUS.SUCCESS)
    }
    if (this.state.isFailureFilterSelected) {
      statusFilterList.push(STATUS.FAILURE)
    }
    if (this.state.isDeferredFilterSelected) {
      statusFilterList.push(STATUS.DEFERRED)
    }
    if (this.state.isBouncedFilterSelected) {
      statusFilterList.push(STATUS.BOUNCED)
    }
    const fetchLogsQueryParams = {
      "startMs": pickerStartTime.utcOffset(0).startOf('day').valueOf(),
      "endMs": pickerEndTime.utcOffset(0).endOf('day').valueOf(),
      "statuses": statusFilterList,
      "direction": this.state.selectedDirection === DIRECTION.INCOMING ? DIRECTION.INCOMING : DIRECTION.OUTGOING,
      "limit": this.state.recordsPerPage,
    };
    const trimmedSenderMail = senderMail.trim();
    const trimmedReceiverMail = receiverMail.trim();

    if (trimmedSenderMail) {
      fetchLogsQueryParams["from"] = trimmedSenderMail;
    }
    if (trimmedReceiverMail) {
      fetchLogsQueryParams["to"] = trimmedReceiverMail;
    }
    if (this.state.senderMail || this.state.receiverMail) {
      this.props.onSubmitMailLogsForm(fetchLogsQueryParams);
    }
  }

  handleRedisCachedMailboxSubmit = () => {
    const searchedEmail = this.state.redisSearchEmail;
    const errorRedisSearchEmailField = validateEmailField(searchedEmail);

    if (errorRedisSearchEmailField) {
      this.setState({ errorRedisSearchEmailField });
      return;
    }

    this.setState({ errorRedisSearchEmailField: false });

    const fetchLogsQueryParams = {
      email: this.state.redisSearchEmail.trim(),
    }

    this.props.onSubmitRedisCachedMailboxForm(fetchLogsQueryParams)
  }

  handleIpLogSubmit = () => {
    const ipAddress = this.state.ipAddress
    const [startTime, endTime] = this.state.logsDuration;

    if (!ipAddress) {
      const errorIpAddress = <FormattedMessage id='empty_ip_address' />
      this.setState({ errorIpAddress });
      return;
    } else if (!isIpAddressValid(ipAddress)) {
      const errorIpAddress = <FormattedMessage id='invalid_ip_address' />
      this.setState({ errorIpAddress });
      return;
    }

    this.setState({ errorIpAddress: "" });

    const fetchLogsQueryParams = {
      ip: ipAddress.trim(),
      startTime: startTime.valueOf(),
      endTime: endTime.valueOf(),
    }
    this.props.onSubmitIpLogsForm(fetchLogsQueryParams);
  }

  handleFormSubmit = e => {
    e.preventDefault();
    switch (this.state.formTypeSelected) {
      case ACTION_DROPDOWN_KEY.MAIL_LOGS:
        this.handleMailLogSubmit();
        break;

      case ACTION_DROPDOWN_KEY.IP_LOGS:
        this.handleIpLogSubmit();
        break;

      case ACTION_DROPDOWN_KEY.INVALID_EMAIL_CACHE:
        this.handleRedisCachedMailboxSubmit();
        break;

      case ACTION_DROPDOWN_KEY.PROTOCOL_LOGS:
        if (this.formRef.current) {
          this.formRef.current.handleSubmit();
        }
        break;

      default:
        break;
    }
  }

  toggleFormTypeOption = () => {
    if (this.props.isFetchLogInProgress) return;

    this.setState(prevState => ({
      ...prevState,
      shouldShowFormTypeDropdown: !prevState.shouldShowFormTypeDropdown
    }));
  }

  resetFormFields = () => {
    this.setState({
      senderMail: '',
      receiverMail: '',
      ipAddress: '',
      logsDuration: this.DEFAULT_LOG_DURATION_START_END,
    })
  }

  changeFormTypeSelected = (formTypeSelected) => {
    this.setState(prevState => ({
      ...prevState,
      formTypeSelected,
      shouldShowFormTypeDropdown: false
    }))
    this.props.cancelLogsRequest();
    this.props.clearRedisCachedMailboxRequest();
    this.resetFormFields();
  }

  getFilterCount = (filterName) => {
    const filterCountMap = this.props.filterCountMap
    if (filterCountMap && filterCountMap[filterName] > 0) {
      return `(${filterCountMap[filterName]})`
    }
    return ""
  }

  render() {
    const { selectedDirection } = this.state;
    const isMailLogOptionSelected = this.state.formTypeSelected === ACTION_DROPDOWN_KEY.MAIL_LOGS
    const formOptionsForMailLogs = (
      <div className="logs-filter-options-container">
        <div>
          <label className="logs-label input-label">
            <FormattedMessage id='from' />
            {selectedDirection === DIRECTION.OUTGOING ? (
              <span className="required-asterisk">*</span>
            ) : (
              <span className="form-field-optional-label"><FormattedMessage id="optional_label" /></span>
            )}
          </label>
          <input name="senderMail"
            type="text"
            className={`logs-input input ${this.state.errorFromField ? 'input-error' : ''}`}
            placeholder={this.props.intl.formatMessage({ id: 'enter_email_address' })}
            autoFocus
            onChange={this.handleSenderChange}
            value={this.state.senderMail} />
          <div className="error dashboard-error">
            {this.state.errorFromField ? this.state.errorFromField : ""}
          </div>
        </div>
        <div>
          <label className="logs-label input-label">
            <FormattedMessage id='to' />
            {selectedDirection === DIRECTION.INCOMING ? (
              <span className="required-asterisk">*</span>
            ) : (
              <span className="form-field-optional-label"><FormattedMessage id="optional_label" /></span>
            )}
          </label>
          <input name="receiverMail"
            type="text"
            className={`logs-input input ${this.state.errorToField ? 'input-error' : ''}`}
            placeholder={this.props.intl.formatMessage({ id: 'enter_email_address' })}
            onChange={this.handleReceiverChange}
            value={this.state.receiverMail} />
          <div className="error dashboard-error">
            {this.state.errorToField ? this.state.errorToField : ""}
          </div>
        </div>
        <div>
          <label className="logs-label input-label"><FormattedMessage id='duration' /></label>
          <RangePicker
            size="large"
            className="t-range-picker"
            dropdownClassName="t-calender-popover"
            onChange={this.handleRangePickerSelection}
            value={this.state.logsDuration}
            allowClear={false}
            disabledDate={(m) => !m.isBetween(this.MAX_LOG_DURATION_DATE, this.TODAY, undefined, "[]")}
          />
        </div>
      </div>
    )
    const formOptionsForIpActions = (
      <div className="logs-filter-options-container">
        <div>
          <label className="logs-label input-label">Ip Address</label>
          <input name="ipAddress"
            type="text"
            className={`logs-input input ${this.state.errorIpAddress ? 'input-error' : ''}`}
            placeholder={this.props.intl.formatMessage({ id: 'enter_ip_address' })}
            autoFocus
            onChange={this.handleIpAddressChange}
            value={this.state.ipAddress} />
          <div className="error dashboard-error">
            {this.state.errorIpAddress || ""}
          </div>
        </div>
        <div>
          <label className="logs-label input-label"><FormattedMessage id='duration' /></label>
          <RangePicker
            size="large"
            className="t-range-picker"
            dropdownClassName="t-calender-popover"
            onChange={this.handleRangePickerSelection}
            value={this.state.logsDuration}
            allowClear={false}
            disabledDate={(m) => !m.isBetween(this.MAX_LOG_DURATION_DATE, this.TODAY, undefined, "[]")}
          />
        </div>
      </div>
    )
    const formOptionsForRedisCachedMailboxActions = (
      <div className="logs-filter-options-container">
        <div>
        <label className="logs-label input-label"><FormattedMessage id='enter_email_address' /></label>
          <input name="redisSearchEmail"
            type="text"
            className={`logs-input input ${this.state.errorRedisSearchEmailField ? 'input-error' : ''}`}
            placeholder={this.props.intl.formatMessage({ id: 'enter_email_address' })}
            autoFocus
            onChange={this.handleRedisEmailChange}
            value={this.state.redisSearchEmail} />
          <div className="error dashboard-error">
            {this.state.errorRedisSearchEmailField || ""}
          </div>
        </div>
      </div>
    )
    const formTypeDropdownOptions = (
      <div className="logs-change-dropdown-menu">
        {
          Object.keys(this.FORM_TYPE_OPTIONS)
            .filter(e => e !== this.state.formTypeSelected)
            .map(e => (
              <div className="dropdown-item log-change-item" key={e}>
                <div className="dropdown-menu-item log-change-menu-item" onClick={() => this.changeFormTypeSelected(e)}>
                  {this.FORM_TYPE_OPTIONS[e]}
                </div>
              </div>
            ))
        }
      </div>
    );    
    let changeLogCSS;
    if (this.props.isFetchLogInProgress) {
      changeLogCSS = "logs-change-button logs-change-button-disabled"
    }
    else {
      changeLogCSS = `logs-change-button ${this.state.shouldShowFormTypeDropdown ? 'logs-change-button-selected' : ''}`
    }

    const getFormOptions = () => {
      switch (this.state.formTypeSelected) {
        case ACTION_DROPDOWN_KEY.MAIL_LOGS:
          return formOptionsForMailLogs;
        case ACTION_DROPDOWN_KEY.INVALID_EMAIL_CACHE:
          return formOptionsForRedisCachedMailboxActions;
        case ACTION_DROPDOWN_KEY.PROTOCOL_LOGS:
          return <ProtocolLogs ref={this.formRef} {...this.props} />;
        default:
          return formOptionsForIpActions;
      }
    };

    return (
      <div className="logs-form-container">
        <form name="formDiv" id="formDiv" className="logs-form" onSubmit={this.handleFormSubmit}>
          <div ref={node => this.node = node}>
            <div onClick={this.toggleFormTypeOption} className={changeLogCSS}>{this.FORM_TYPE_OPTIONS[this.state.formTypeSelected]} <span className="logs-change-button-arrow"></span></div>
            {this.state.shouldShowFormTypeDropdown ? formTypeDropdownOptions : ""}
          </div>
          {getFormOptions()}
          <button type="primary"
            className="button button-primary logs-button"
            name="btndashBoard"
            disabled={this.props.fetchingLogs}>
            <FormattedMessage id="get_logs" />
          </button>
        </form>
        {isMailLogOptionSelected ? <div className="log-filter">
          <span><FormattedMessage id='label_filters' /></span>
          <span className="log-filter-heading"><FormattedMessage id='label_direction' /></span>
          <label className='log-filter-radio' >
            <input type='radio' id='success' checked={this.state.selectedDirection === DIRECTION.INCOMING} onChange={() => this.toggleDirectionFilter(DIRECTION.INCOMING)} />
            <span className='log-filter-item'><FormattedMessage id='incoming' /> {this.getFilterCount(DIRECTION.INCOMING)}</span>
          </label>
          <label className='log-filter-radio' >
            <input type='radio' id='success' checked={this.state.selectedDirection === DIRECTION.OUTGOING} onChange={() => this.toggleDirectionFilter(DIRECTION.OUTGOING)} />
            <span className='log-filter-item'><FormattedMessage id='outgoing' />{this.getFilterCount(DIRECTION.OUTGOING)}</span>
          </label>
          <span className="log-filter-heading"><FormattedMessage id='label_status' /></span>
          <label className='log-filter-checkbox' >
            <input type='checkbox' id='success' checked={this.state.isSuccessFilterSelected} onChange={() => this.toggleStatusFilter(STATUS.SUCCESS)} />
            <span className='log-filter-item'><FormattedMessage id='success' /> {this.getFilterCount(STATUS.SUCCESS)}</span>
          </label>
          <label className='log-filter-checkbox'>
            <input type='checkbox' id='bounced' checked={this.state.isBouncedFilterSelected} onChange={() => this.toggleStatusFilter(STATUS.BOUNCED)} />
            <span className='log-filter-item'><FormattedMessage id='bounced' /> {this.getFilterCount(STATUS.BOUNCED)}</span>
          </label>
          <label className='log-filter-checkbox' >
            <input type='checkbox' id='failure' checked={this.state.isFailureFilterSelected} onChange={() => this.toggleStatusFilter(STATUS.FAILURE)} />
            <span className='log-filter-item'><FormattedMessage id='failure' /> {this.getFilterCount(STATUS.FAILURE)}</span>
          </label>
          <label className='log-filter-checkbox'>
            <input type='checkbox' id='deferred' checked={this.state.isDeferredFilterSelected} onChange={() => this.toggleStatusFilter(STATUS.DEFERRED)} />
            <span className='log-filter-item'><FormattedMessage id='deferred' /> {this.getFilterCount(STATUS.DEFERRED)}</span>
          </label>
        </div> : null}
      </div>
    );
  };
}

const mapStateToProps = state => {
  return {
    fetchingLogs: state.logs.fetchingLogs,
    fromFieldIsInvalid: state.logs.fromIsInvalid,
    toFieldIsInvalid: state.logs.toFieldIsInvalid,
    isFetchLogInProgress: state.logs.fetchingLogs,
    filterCountMap: state.logs.filterCountMap
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ cancelLogsRequest, clearRedisCachedMailboxRequest }, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(GetLogsForm));
